import { Autocomplete, IAutocompleteResponse } from '../Helper/Autocomplete';
import { ArrowNavigation, HTMLListElement } from '../Helper/ArrowNavigation';

class MainSearch {
    private _autocomplete: HTMLElement | undefined;
    private _arrowNavigation: ArrowNavigation | undefined;
    private _input: HTMLInputElement | undefined;
    private _status: HTMLElement | undefined;
    private _button: HTMLElement | undefined;

    constructor(el: HTMLElement) {
        const input = el.querySelector('.main-search__input');
        const autocomplete = el.querySelector('.main-search__autocomplete');
        const status = el.querySelector('.main-search__status');
        const button = el.querySelector('.search-button');

        if (
            !(
                input instanceof HTMLInputElement &&
                autocomplete instanceof HTMLElement &&
                status instanceof HTMLElement &&
                button instanceof HTMLElement
            )
        ) {
            return;
        }

        this._autocomplete = autocomplete;
        this._input = input;
        this._status = status;
        this._button = button;

        new Autocomplete(input, this._autocompleteCallback.bind(this));
        this._arrowNavigation = new ArrowNavigation(
            autocomplete as HTMLListElement,
            'main-search__autocomplete-item--active',
            input as HTMLInputElement,
            button as HTMLElement,
        );
    }

    private _updateStatus(resultCount: number) {
        if (!this._status) {
            return;
        }

        if (!resultCount) {
            return;
        }

        if (resultCount === 0) {
            this._status.innerText = 'Keine Ergebnisse.';
        } else if (resultCount === 1) {
            this._status.innerText = resultCount + ' Ergebnis vorhanden.';
        } else if (resultCount === undefined) {
            this._status.innerText = '';
        } else {
            this._status.innerText = resultCount + ' Ergebnisse vorhanden.';
        }
    }

    private _autocompleteCallback(results: IAutocompleteResponse[]): void {
        if (!this._autocomplete) {
            return;
        }

        if (!this._input) {
            return;
        }

        this._arrowNavigation?.reset();

        while (this._autocomplete.firstElementChild) {
            this._autocomplete.removeChild(this._autocomplete.firstElementChild);
        }

        if (results.length) {
            this._input.setAttribute('aria-expanded', 'true');
            this._autocomplete.classList.add('main-search__autocomplete--visible');
            results.forEach((result, index) => {
                const li = document.createElement('li');
                const a = document.createElement('a');
                let idValue = 'autocomplete-' + index;

                li.setAttribute('tabindex', '-1');
                li.setAttribute('role', 'option');
                li.setAttribute('aria-selected', 'false');
                li.setAttribute('id', 'option-' + idValue);

                a.classList.add('main-search__autocomplete-link');
                a.href = result.link;
                a.textContent = result.title;

                li.appendChild(a);
                (this._autocomplete as HTMLElement).appendChild(li);
            });
            this._updateStatus(results.length);
        } else {
            this._input.setAttribute('aria-expanded', 'false');
            this._autocomplete.classList.remove('main-search__autocomplete--visible');
            (this._status as HTMLElement).innerText = '';
        }
    }

    public static init(): void {
        Array.from(document.querySelectorAll('.main-search')).forEach((el) => {
            if (el instanceof HTMLElement) {
                new MainSearch(el);
            }
        });
    }
}

export { MainSearch };
