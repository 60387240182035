enum KEY {
    DOWN = 'ArrowDown',
    UP = 'ArrowUp',
    ENTER = 'Enter',
    TAB = 'Tab',
}

enum MS_KEY {
    DOWN = 'Down',
    UP = 'Up',
    TAB = 'Tab',
}

type HTMLListElement = HTMLUListElement | HTMLOListElement | HTMLDListElement | HTMLDataListElement;

class ArrowNavigation {
    private _input: HTMLInputElement;
    private _list: HTMLElement;
    private _button: HTMLElement;
    private _classname: string;
    private _position: number = -1;

    constructor(list: HTMLListElement, classname: string, input: HTMLInputElement, button: HTMLElement) {
        this._input = input;
        this._list = list;
        this._button = button;
        this._classname = classname;
        document.addEventListener('keydown', this._onKeyDown.bind(this));
    }

    public reset(position: number = -1): void {
        if (position === this._position) {
            return;
        }
        position = Math.min(Math.max(-1, position), this._list.children.length - 1);
        this._focus(position);
    }

    private _unselectAll() {
        Array.from(this._list.querySelectorAll('.' + this._classname)).forEach((el) => {
            el.classList.remove(this._classname);
            el.setAttribute('aria-selected', 'false');
            el.removeAttribute('id');
        });
    }

    private _focus(position: number) {
        this._unselectAll();
        this._position = position;
        if (position >= 0) {
            this._list.children[position].classList.add(this._classname);
            this._list.children[position].setAttribute('aria-selected', 'true');
            (this._list.children[position].children[0] as HTMLElement).focus();
        }
    }

    private _focusNext(): boolean {
        let newPosition = Math.min(this._position + 1, this._list.children.length - 1);
        if (newPosition === this._position) {
            return false;
        }
        this._focus(newPosition);
        return true;
    }

    private _focusPrev(): boolean {
        let newPosition = Math.max(this._position - 1, -1);
        if (newPosition === this._position) {
            return false;
        }
        this._focus(newPosition);
        if (newPosition === -1) {
            (this._input as HTMLElement).focus();
        }
        return true;
    }

    private _submit(): boolean {
        if (this._position < 0) {
            return false;
        }
        const link = this._list.children[this._position].querySelector('a');
        if (!link) {
            console.error('Autocomplete item has no link element');
            return false;
        }
        location.href = link.href;
        return true;
    }

    private _tabOutInput(): boolean {
        this.reset();
        this._input.focus();
        return true;
    }

    private _tabOutButton(): boolean {
        const searchButton = this._button;
        if (!searchButton) {
            console.error('Search has no button element');
            return false;
        }
        this.reset();
        searchButton.focus();
        return true;
    }

    private _onKeyDown(event: KeyboardEvent) {
        if (ArrowNavigation._isHidden(this._list)) {
            return;
        }

        let fn: () => boolean = () => false;

        switch (event.key || event.keyCode) {
            case KEY.DOWN:
            case MS_KEY.DOWN:
                fn = this._focusNext.bind(this);
                break;
            case KEY.UP:
            case MS_KEY.UP:
                fn = this._focusPrev.bind(this);
                break;
            case KEY.ENTER:
                fn = this._submit.bind(this);
                break;
            case KEY.TAB:
            case MS_KEY.TAB:
                if (!event.shiftKey) {
                    fn = this._tabOutButton.bind(this);
                } else {
                    fn = this._tabOutInput.bind(this);
                }
                break;
        }

        if (fn()) {
            event.preventDefault();
        }
    }

    private static _isHidden(el: HTMLElement): boolean {
        return el.offsetParent === null || window.getComputedStyle(el).display === 'none';
    }
}

export { ArrowNavigation, HTMLListElement };
