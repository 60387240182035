interface IClassnames {
    active: string;
    enter?: string;
    enterTo?: string;
    enterActive?: string;
    leave?: string;
    leaveTo?: string;
    leaveActive?: string;
}

class Animate {
    private _active: string = '';
    private _enter: string = '';
    private _enterTo: string = '';
    private _enterActive: string = '';
    private _leave: string = '';
    private _leaveTo: string = '';
    private _leaveActive: string = '';

    private static _instance: Animate;

    private static readonly _classnames: IClassnames = {
        active: 'animate',
        enter: 'animate-enter',
        enterActive: 'animate-enter-active',
        enterTo: 'animate-enter-to',
        leave: 'animate-leave',
        leaveActive: 'animate-leave-active',
        leaveTo: 'animate-leave-to',
    };

    constructor(active?: IClassnames | string, animateTo?: string, animationActive?: string, animateFrom?: string) {
        if (typeof active === 'string' && active) {
            this._active = active;

            if (typeof animateTo === 'string' && animateTo) {
                this._enterTo = this._leave = animateTo;

                if (typeof animationActive === 'string' && animationActive) {
                    this._enterActive = this._leaveActive = animationActive;

                    if (typeof animateFrom === 'string' && animateFrom) {
                        this._enter = this._leaveTo = animateFrom;
                    }
                }
            }
        } else if (typeof active === 'object') {
            const classes = Animate._classnames;

            for (let i in <IClassnames>active) {
                if (classes.hasOwnProperty(i)) {
                    // @ts-ignore
                    this['_' + i] = active[i];
                }
            }
        } else {
            const classes = Animate._classnames;

            for (let i in classes) {
                if (classes.hasOwnProperty(i)) {
                    // @ts-ignore
                    this['_' + i] = classes[i];
                }
            }
        }
    }

    public show(element: HTMLElement): Promise<void> {
        return new Promise((resolve, reject) => {
            requestAnimationFrame(() => {
                element.classList.add(this._active);

                if (this._enter) {
                    element.classList.add(this._enter);
                }
                if (this._enterActive) {
                    element.classList.add(this._enterActive);
                }

                requestAnimationFrame(() => {
                    this._awaitTransition(element)
                        .then(resolve)
                        .catch(reject)
                        .finally(() => {
                            if (this._enterActive) {
                                element.classList.remove(this._enterActive);
                            }
                        });

                    element.classList.add(this._enterTo);
                });
            });
        });
    }

    public hide(element: HTMLElement): Promise<void> {
        return new Promise((resolve, reject) => {
            requestAnimationFrame(() => {
                element.classList.remove(this._enterTo);
                element.classList.add(this._leave);

                if (this._leaveActive) {
                    element.classList.add(this._leaveActive);
                }

                requestAnimationFrame(() => {
                    this._awaitTransition(element)
                        .then(resolve)
                        .catch(reject)
                        .finally(() => {
                            if (this._leaveActive) {
                                element.classList.remove(this._leaveActive);
                            }
                            element.classList.remove(this._active);
                        });

                    element.classList.remove(this._leave);
                    if (this._leaveTo) {
                        element.classList.add(this._leaveTo);
                    }
                });
            });
        });
    }

    public toggle(element: HTMLElement): Promise<void> {
        if (element.classList.contains(this._active)) {
            return this.hide(element);
        }
        return this.show(element);
    }

    private _awaitTransition(element: HTMLElement): Promise<void> {
        return new Promise((resolve, reject) => {
            function unregisterHandler() {
                element.removeEventListener('transitionend', onTransitionEndHandler);
                element.removeEventListener('transitioncancel', onTransitionCancelHandler);
            }

            function onTransitionEndHandler() {
                unregisterHandler();
                resolve();
            }

            function onTransitionCancelHandler() {
                unregisterHandler();
                reject();
            }

            element.addEventListener('transitionend', onTransitionEndHandler);
            element.addEventListener('transitioncancel', onTransitionCancelHandler);
        });
    }

    private static get instance() {
        if (!Animate._instance) {
            Animate._instance = new Animate();
        }
        return Animate._instance;
    }

    public static show(el: HTMLElement): Promise<void> {
        return Animate.instance.show(el);
    }

    public static hide(el: HTMLElement): Promise<void> {
        return Animate.instance.hide(el);
    }

    public static toggle(el: HTMLElement): Promise<void> {
        return Animate.instance.toggle(el);
    }
}

export { Animate };
