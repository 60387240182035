class FilterMobileItem {
    private _select: HTMLSelectElement;
    private _overlay: HTMLElement | undefined;

    constructor(el: HTMLElement) {
        const select = el.querySelector('.filter-mobile-item__select');
        if (!(select instanceof HTMLSelectElement)) {
            throw new Error('Invalid mobile filter item markup. Select-item is required');
        }
        this._select = select;

        this._select.addEventListener('change', this._onSelectionChange.bind(this));
    }

    private _onSelectionChange() {
        const value = this._select.value;

        if (value !== '') {
            document.location.href = value;
        }
    }

    public static init() {
        Array.from(document.querySelectorAll('.filter-mobile-item')).forEach((filter) => {
            if (filter instanceof HTMLElement) {
                new FilterMobileItem(filter);
            }
        });
    }
}

export { FilterMobileItem };
