export class SolrLinks {
    public static init(): void {
        // Collect URL parameters of solr
        const solrParams = location.href
            .split('?')
            .pop()
            ?.split('&')
            .filter((param) => param.startsWith('tx_solr'))
            .join('&');

        // Add params to links which have the companySolrLink toggle attribute
        if (solrParams) {
            document.querySelectorAll('[data-toggle=companySolrLink]').forEach((el: Element): void => {
                const link = el as HTMLAnchorElement;
                link.href += (link.href.includes('?') ? '&' : '?') + solrParams;
            });
        }
    }
}
