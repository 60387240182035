class Scroll {
    //********************************************************
    // PUBLIC STATIC PROPERTIES
    //********************************************************
    public static DEFAULT_SCROLL_DURATION = 600;

    //********************************************************
    // PRIVATE VARIABLES
    //********************************************************
    private _container: HTMLElement;
    private _scrollDuration: number;
    private _interruptionCallbacks: (() => void)[] = [];
    private _animationActive: boolean = false;

    //********************************************************
    // CONSTRUCTOR
    //********************************************************
    constructor(
        scrollContainer: HTMLElement,
        { scrollDuration = Scroll.DEFAULT_SCROLL_DURATION }: { scrollDuration?: number } = {},
    ) {
        this._container = scrollContainer;
        this._scrollDuration = scrollDuration;
    }

    public scrollIntoView(target: HTMLElement): Promise<void> {
        return this._smoothScroll(this._getOffset(target));
    }

    public interrupt(): Promise<void> {
        if (!this._animationActive) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            this._interruptionCallbacks.push(resolve);

            // REJECT AFTER TWO RENDER CYCLES
            //********************************************************
            window.requestAnimationFrame(() => {
                window.requestAnimationFrame(() => {
                    reject();
                });
            });
        });
    }

    //********************************************************
    // PRIVATE METHODS
    //********************************************************
    private _smoothScroll(targetPosition: number): Promise<void> {
        const startScrollPosition = Scroll._getScrollPosition(this._container);

        if (startScrollPosition !== targetPosition) {
            const startTime = Scroll.now();

            return this.interrupt()
                .then(() => {
                    this._animationActive = true;
                    return new Promise<void>((resolve, reject) => {
                        this._step(startTime, startScrollPosition, targetPosition, resolve, reject);
                    });
                })
                .catch(() => {
                    console.debug('Scroll animation aborted due user interruption');
                })
                .finally(() => {
                    this._animationActive = false;
                });
        } else {
            return Promise.resolve();
        }
    }

    private _step(
        startTime: number,
        startScrollPosition: number,
        targetScrollPosition: number,
        successCb: () => void,
        errorCb: () => void,
    ): void {
        if (this._interruptionCallbacks.length) {
            const callbacks = [...this._interruptionCallbacks];
            this._interruptionCallbacks.length = 0;

            callbacks.forEach((cb) => cb());

            return errorCb();
        }

        window.requestAnimationFrame(() => {
            const currentTime = Scroll.now();
            const scrollTimeElapsed = Math.min(1, (currentTime - startTime) / this._scrollDuration);
            const easeFactor = Scroll._ease(scrollTimeElapsed);
            const nextScrollPosition = startScrollPosition + (targetScrollPosition - startScrollPosition) * easeFactor;

            this._container.scrollLeft = nextScrollPosition;

            if (nextScrollPosition !== targetScrollPosition) {
                this._step(startTime, startScrollPosition, targetScrollPosition, successCb, errorCb);
            } else {
                successCb();
            }
        });
    }

    private _getOffset(el: HTMLElement): number {
        let offset = 0;
        for (; el !== this._container; el = el.offsetParent as HTMLElement) {
            offset += el.offsetLeft;
        }
        return offset;
    }

    //********************************************************
    // PRIVATE STATIC METHODS
    //********************************************************
    private static _ease(k: number): number {
        return 0.5 * (1 - Math.cos(Math.PI * k));
    }

    private static _getScrollPosition(el: Element): number {
        return el === document.body ? window.scrollX || window.pageYOffset : el.scrollLeft;
    }

    private static now() {
        return window.performance && window.performance.now ? window.performance.now() : Date.now();
    }
}

export { Scroll };
