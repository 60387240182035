import constants from '../Generated/constants.js';

interface IMqlObject {
    [key: string]: MediaQueryList;
}

interface IBreakpoints {
    [key: string]: boolean;
}

class BreakpointHelper {
    private _mql: IMqlObject = {};

    constructor() {
        this._mql = {};

        Object.entries(BreakpointHelper.defaultBreakpoints).forEach(([key, val]) => {
            this._mql[key] = window.matchMedia(`(min-width: ${val}px)`);
        });
    }

    get breakpoints(): IBreakpoints {
        const xsAndUp = true;

        const smAndUp = this._mql.sm.matches;
        const mdAndUp = this._mql.md.matches;
        const lgAndUp = this._mql.lg.matches;
        const xlAndUp = this._mql.xl.matches;

        const xsAndDown = !smAndUp;
        const smAndDown = !mdAndUp;
        const mdAndDown = !lgAndUp;
        const lgAndDown = !xlAndUp;
        const xlAndDown = true;

        const xsOnly = !smAndUp;
        const smOnly = smAndUp && !mdAndUp;
        const mdOnly = mdAndUp && !lgAndUp;
        const lgOnly = lgAndUp && !xlAndUp;
        const xlOnly = xlAndUp;

        return {
            xsAndDown,
            xsAndUp,
            xsOnly,
            smAndDown,
            smAndUp,
            smOnly,
            mdAndDown,
            mdAndUp,
            mdOnly,
            lgAndDown,
            lgAndUp,
            lgOnly,
            xlAndDown,
            xlAndUp,
            xlOnly,
        };
    }

    static get defaultBreakpoints(): typeof constants.breakpoints {
        return constants.breakpoints;
    }
}

const breakpointHelper = new BreakpointHelper();

export { breakpointHelper, BreakpointHelper };
