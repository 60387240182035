import { Scroll } from '../Helper/Scroll';
import { BreakpointHelper } from '../Helper/Breakpoints';

enum KEY {
    LEFT = 'ArrowLeft',
    RIGHT = 'ArrowRight',
}

enum MS_KEY {
    LEFT = 'Left',
    RIGHT = 'Right',
}

const breakpointHelper = new BreakpointHelper();

class NewsSlider {
    private static readonly VIEWPORT_TOLERANCE = 30;

    private _slider: HTMLElement;
    private _sliderContent: HTMLElement;
    private _sliderItems: HTMLElement[];
    private _scroll: Scroll;
    private _isLgAndUp: boolean = breakpointHelper.breakpoints.lgAndUp;
    private _isMouseOver: boolean = false;
    private _buttons: HTMLElement[];
    private _wrapper: HTMLElement;

    constructor(el: HTMLElement) {
        Array.from(el.querySelectorAll('.news-slider__arrow')).forEach((arrow) => {
            if (arrow instanceof HTMLElement) {
                arrow.addEventListener('click', this._onArrowEvent.bind(this));
            }
        });

        this._wrapper = el.querySelector('.news-slider__wrapper') as HTMLElement;
        this._slider = el.querySelector('.news-slider__slider') as HTMLElement;
        this._sliderContent = el.querySelector('.news-slider__content') as HTMLElement;
        this._sliderItems = Array.from(el.querySelectorAll('.news-slider__item')) as HTMLElement[];
        this._buttons = Array.from(el.querySelectorAll('.news-slider__arrow')) as HTMLElement[];

        this._scroll = new Scroll(this._slider);

        window.addEventListener('resize', this._onWindowResize.bind(this));
        window.addEventListener('load', this._onLoad.bind(this));
        if (this._buttons.length) {
            document.addEventListener('keydown', this._onArrowKeyDown.bind(this));
        }
    }

    private _onMouseOver() {
        this._wrapper.onmouseover = () => {
            this._isMouseOver = true;
        };

        this._wrapper.onmouseleave = () => {
            this._isMouseOver = false;
        };
    }

    private _onWindowResize() {
        const isLgAndUpAfterResize = breakpointHelper.breakpoints.lgAndUp;

        if (!this._isLgAndUp && isLgAndUpAfterResize) {
            const firstVisibleItem = this._getVisibleItems()[0];
            const targetItem = (firstVisibleItem.previousElementSibling as HTMLElement) || firstVisibleItem;

            this._scroll.scrollIntoView(targetItem);
        }

        this._isLgAndUp = isLgAndUpAfterResize;

        if (this._isLgAndUp) {
            this._onMouseOver();
        }

        this._setAttributes();
    }

    private _onLoad() {
        if (this._isLgAndUp) {
            this._onMouseOver();
        }

        this._setAttributes();
    }

    private _onArrowEvent(event: UIEvent) {
        let left = undefined;
        if (event instanceof MouseEvent) {
            if (!(event.currentTarget instanceof HTMLElement)) {
                return;
            }
            left = event.currentTarget.dataset.direction === 'prev';
        } else {
            if (!(event instanceof KeyboardEvent)) {
                return;
            }
            left = event.key === 'ArrowLeft' || event.key === 'Left';
        }

        const visibleItems = this._getVisibleItems();
        const currentItem = this._getActiveSliderItem();

        this._sliderItems.forEach((item) => {
            item.setAttribute('aria-hidden', 'true');
            item.setAttribute('tabindex', '-1');
            item.classList.remove('is-active');
        });

        if (left) {
            const currentPrevious = currentItem[0].previousElementSibling;
            if (currentPrevious) {
                // if previousElement is not null go one item to left
                this._scroll.scrollIntoView(currentPrevious as HTMLElement);
                currentPrevious.removeAttribute('aria-hidden');
                currentPrevious.classList.add('is-active');
            } else {
                this._scroll.scrollIntoView(this._sliderItems[this._sliderItems.length - visibleItems.length]);
                this._sliderItems[this._sliderItems.length - 1].removeAttribute('aria-hidden');
                this._sliderItems[this._sliderItems.length - 1].classList.add('is-active');
            }
        } else {
            // to right
            const currentNext = currentItem[0].nextElementSibling;
            if (currentNext) {
                this._scroll.scrollIntoView(visibleItems[1]);
                currentNext.removeAttribute('aria-hidden');
                currentNext.classList.add('is-active');
            } else {
                this._scroll.scrollIntoView(this._sliderItems[0]);
                this._sliderItems[0].removeAttribute('aria-hidden');
                this._sliderItems[0].classList.add('is-active');
            }
        }
    }

    private _onArrowKeyDown(event: KeyboardEvent) {
        Array.from(this._buttons).forEach((arrow) => {
            if (NewsSlider._isHidden(arrow)) {
                return;
            }
        });

        if (!(this._isMouseOver || this._wrapper.contains(document.activeElement))) {
            return;
        }

        let fn: () => boolean = () => false;

        switch (event.key) {
            case KEY.LEFT:
            case MS_KEY.LEFT:
                this._onArrowEvent(event);
                break;
            case KEY.RIGHT:
            case MS_KEY.RIGHT:
                this._onArrowEvent(event);
                break;
        }

        if (fn()) {
            event.preventDefault();
        }
    }

    private _getVisibleItems(): HTMLElement[] {
        const viewportStart = this._slider.scrollLeft - NewsSlider.VIEWPORT_TOLERANCE;
        const viewportEnd = this._slider.scrollLeft + this._slider.offsetWidth + NewsSlider.VIEWPORT_TOLERANCE;

        return this._sliderItems.filter((item) => {
            return item.offsetLeft >= viewportStart && item.offsetLeft + item.offsetWidth <= viewportEnd;
        });
    }

    private _getActiveSliderItem(): HTMLElement[] {
        return this._sliderItems.filter((item) => {
            return item.classList.contains('is-active');
        });
    }

    private _setAttributes() {
        if (this._isLgAndUp) {
            const lang = ((document.body.parentNode as HTMLElement).getAttribute('lang') as string).slice(0, 2);
            this._sliderContent.setAttribute('aria-live', 'polite');
            const sliderCount = this._sliderItems.length;
            this._sliderItems.forEach((item, index) => {
                item.setAttribute('aria-hidden', 'true');
                item.querySelector('a')?.setAttribute('tabindex', '-1');

                if (lang === 'de') {
                    item.setAttribute('aria-label', 'Thema ' + (index + 1) + ' von ' + sliderCount);
                } else {
                    item.setAttribute('aria-label', 'Item ' + (index + 1) + ' of ' + sliderCount);
                }
            });
            this._sliderItems[0].classList.add('is-active');
            this._sliderItems[0].removeAttribute('aria-hidden');
            this._sliderItems[0].querySelector('a')?.removeAttribute('tabindex');
        } else {
            this._sliderContent.removeAttribute('aria-live');
            this._sliderItems.forEach((item) => {
                item.removeAttribute('aria-hidden');
                item.removeAttribute('aria-label');
                item.querySelector('a')?.removeAttribute('tabindex');
            });
        }
    }

    private static _isHidden(el: HTMLElement): boolean {
        return el.offsetParent === null || window.getComputedStyle(el).display === 'none';
    }

    public static init() {
        Array.from(document.querySelectorAll('.news-slider')).forEach((el) => {
            if (el instanceof HTMLElement) {
                new NewsSlider(el);
            }
        });
    }
}

export { NewsSlider };
