import { BreakpointHelper } from '../Helper/Breakpoints';

const breakpointHelper = new BreakpointHelper();

class ExternalLink {
    private _el: HTMLElement;
    private _stickyOffset: number = 0;
    private _shouldBeSticky: boolean = false;

    constructor(el: HTMLElement) {
        this._el = el;

        if (!document.body.classList.contains('has-external-link')) {
            document.body.classList.add('has-external-link');
        }

        window.addEventListener('resize', this._onWindowResize.bind(this));
        this._onWindowResize();

        const trigger = el.querySelector('.external-link__mobile-toggle');
        if (trigger) {
            trigger.addEventListener('click', this._onTriggerClick.bind(this));
        }
    }

    private _onWindowResize() {
        if (breakpointHelper.breakpoints.mdAndDown) {
            this._shouldBeSticky = false;
            return;
        }

        const column = this._el.offsetParent;
        if (column) {
            const contentHeight = ExternalLink._getContentHeight(column);
            const contactPartnerHeight = ExternalLink._getHeightWithMargins(this._el);
            const freeSpace = column.clientHeight - contentHeight;

            this._shouldBeSticky = freeSpace > contactPartnerHeight;
            if (this._shouldBeSticky) {
                this._stickyOffset = contentHeight;
            }
        } else {
            this._shouldBeSticky = false;
        }
    }

    private _onTriggerClick(event: Event): void {
        event.preventDefault();
    }

    private static _getContentHeight(el: Element): number {
        return Array.from(el.children).reduce((height, el) => {
            return height + ExternalLink._getHeightWithMargins(el);
        }, 0);
    }

    private static _getHeightWithMargins(el: Element): number {
        const styles = getComputedStyle(el);
        const height =
            el instanceof HTMLElement
                ? el.offsetHeight
                : el.clientHeight +
                  ExternalLink._parsePropertyToInt(styles, 'borderTop') +
                  ExternalLink._parsePropertyToInt(styles, 'borderBottom');

        return (
            height +
            ExternalLink._parsePropertyToInt(styles, 'marginTop') +
            ExternalLink._parsePropertyToInt(styles, 'marginBottom')
        );
    }

    private static _parsePropertyToInt(style: CSSStyleDeclaration, property: keyof CSSStyleDeclaration): number {
        return parseInt((style[property] || 0) + '');
    }

    public static init(): void {
        Array.from(document.querySelectorAll('.external-link__wrapper')).forEach((el) => {
            new ExternalLink(el.parentElement as HTMLElement);
        });
    }
}

export { ExternalLink };
