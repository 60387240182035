class DesktopNav {
    private _nav: HTMLElement;
    private _mainItems: HTMLElement[];

    constructor(nav: HTMLElement) {
        this._nav = nav;
        this._mainItems = Array.from(nav.children).filter((item) =>
            item.classList.contains('nav-main__item--has-submenu'),
        ) as HTMLElement[];

        this._mainItems.forEach((item) => {
            item.addEventListener('mouseenter', DesktopNav._onItemOver);
            item.addEventListener('mouseleave', DesktopNav._onItemOut);
        });
    }

    private static _onItemOver(event: Event): void {
        const item = event.target as HTMLElement;
        const parent = item.offsetParent as HTMLElement;
        const submenu = Array.from(item.children).filter((el) =>
            el.classList.contains('nav-main__submenu'),
        )[0] as HTMLElement;

        const submenuBounds = {
            left: submenu.offsetLeft,
            right: submenu.offsetLeft + submenu.offsetWidth,
        };

        const parentBounds = {
            left: parent.clientLeft,
            right: parent.clientLeft + parent.clientWidth,
        };

        if (submenuBounds.right > parentBounds.right) {
            submenu.style.right = '0';
        }

        if (submenuBounds.left < parentBounds.left) {
            submenu.style.left = '0';
        }
    }

    private static _onItemOut(event: Event): void {
        const item = event.target as HTMLElement;
        const submenu = Array.from(item.children).filter((el) =>
            el.classList.contains('nav-main__submenu'),
        )[0] as HTMLElement;

        submenu.style.left = '';
        submenu.style.right = '';
    }
}

class MobileNav {
    private _nav: HTMLElement;
    private _submenuItems: HTMLElement[];

    constructor(nav: HTMLElement) {
        this._nav = nav;
        this._submenuItems = Array.from(nav.querySelectorAll('.nav-main__item--has-submenu'));

        this._submenuItems.forEach((item) => {
            const itemContent = Array.from(item.children).find((child) =>
                child.classList.contains('nav-main__content'),
            ) as HTMLElement;
            const submenuToggle = Array.from(itemContent.children).find((child) =>
                child.classList.contains('nav-main__submenu-toggle'),
            );

            if (!submenuToggle) {
                console.warn(
                    "Menu Item was marked with 'nav-main__item--has-submenu' but does not contain submenu toggle",
                );
                return;
            }

            submenuToggle.addEventListener('click', this._onSubmenuToggleClick.bind(this));
        });
    }

    private _onSubmenuToggleClick(event: Event): void {
        event.preventDefault();

        const toggle = event.currentTarget as HTMLElement;
        const item = (toggle.parentElement as HTMLElement).parentElement as HTMLElement;

        if (item.classList.contains('nav-main__item--open')) {
            this._closeMenu(item);
        } else {
            this._openMenu(item);

            Array.from((item.parentElement as HTMLElement).children)
                .filter((menu) => menu.classList.contains('nav-main__item--open') && menu !== item)
                .forEach((el) => {
                    this._closeMenu(el as HTMLElement);
                });
        }
    }

    private _closeMenu(parentItem: HTMLElement): void {
        const submenu = Array.from(parentItem.children).find((sibling) =>
            sibling.classList.contains('nav-main__submenu'),
        ) as HTMLElement;

        requestAnimationFrame(() => {
            parentItem.classList.remove('nav-main__item--open');
            submenu.style.maxHeight = submenu.scrollHeight + 'px';

            requestAnimationFrame(() => {
                submenu.addEventListener('transitionend', function onOpenTransitionEndHandler() {
                    submenu.removeEventListener('transitionend', onOpenTransitionEndHandler);
                    submenu.style.display = '';
                    submenu.style.maxHeight = '';

                    Array.from(submenu.querySelectorAll('.nav-main__item--open')).forEach((el) => {
                        el.classList.remove('nav-main__item--open');
                        Array.from(el.children)
                            .filter((menu) => menu.classList.contains('nav-main__submenu'))
                            .forEach((menu) => {
                                if (menu instanceof HTMLElement) {
                                    menu.style.display = '';
                                    menu.style.maxHeight = '';
                                }
                            });
                    });
                });

                submenu.style.maxHeight = '0px';
            });
        });
    }

    private _openMenu(parentItem: HTMLElement): void {
        const submenu = Array.from(parentItem.children).find((sibling) =>
            sibling.classList.contains('nav-main__submenu'),
        ) as HTMLElement;

        requestAnimationFrame(() => {
            parentItem.classList.add('nav-main__item--open');

            submenu.style.display = 'block';
            submenu.style.maxHeight = '0';

            requestAnimationFrame(() => {
                submenu.addEventListener('transitionend', function onCloseTransitionEndHandler() {
                    submenu.removeEventListener('transitionend', onCloseTransitionEndHandler);
                    submenu.style.maxHeight = '';
                });

                submenu.style.maxHeight = submenu.scrollHeight + 'px';
            });
        });
    }
}

class NavMain {
    public static init(): void {
        Array.from(document.querySelectorAll('.nav-main')).forEach((nav) => {
            if (nav instanceof HTMLElement) {
                if (nav.classList.contains('nav-main--mobile')) {
                    new MobileNav(nav);
                } else {
                    if (!nav.classList.contains('nav-main--desktop')) {
                        console.warn(
                            'Missing target-modificator-class on nav-main molecule. Element will be treated as desktop navigation',
                        );
                        nav.classList.add('nav-main--desktop');
                    }
                    new DesktopNav(nav);
                }
            }
        });
    }
}

export { NavMain };
