class Youtube {
    private _onAcceptHandler: (event: Event) => void;
    private _container: HTMLElement;
    private _outlet: HTMLElement;

    constructor(el: HTMLElement) {
        if (!el.dataset.src) {
            throw new Error('Youtube container has no valid video-url set');
        }

        const outlet = el.querySelector('.youtube__outlet');
        if (!(outlet instanceof HTMLElement)) {
            throw new Error('Youtube container has no video-outlet');
        }
        this._outlet = outlet;

        this._onAcceptHandler = this._onAccept.bind(this);
        this._container = el;

        Array.from(el.querySelectorAll('[data-accept]')).forEach((btn) => {
            btn.addEventListener('click', this._onAcceptHandler);
        });
    }

    public accept() {
        const iframe = document.createElement('iframe');

        iframe.src = this._container.dataset.src as string;
        iframe.allowFullscreen = true;
        iframe.allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
        iframe.classList.add('youtube__outlet');
        iframe.addEventListener('load', this._onVideoLoaded.bind(this));
        iframe.addEventListener('error', this._onVideoLoadError.bind(this));

        if (this._outlet.replaceWith) {
            this._outlet.replaceWith(iframe);
        } else {
            const parent = this._outlet.parentElement as Element;
            parent.removeChild(this._outlet);
            parent.appendChild(iframe);
        }
    }

    private _onAccept(event: Event) {
        event.preventDefault();
        if (event.currentTarget instanceof HTMLButtonElement) {
            event.currentTarget.textContent = 'Loading...';
            event.currentTarget.disabled = true;
        }
        this.accept();
    }

    private _onVideoLoaded() {
        this._container.classList.remove('youtube--hidden');
    }

    private _onVideoLoadError() {
        const overlayContent = this._container.querySelector('.youtube__overlay-content');

        if (!overlayContent) {
            alert('Error loading youtube video');
            return;
        }

        overlayContent.innerHTML =
            '<p>An error occured while trying to load the youtube-video. Please try again later</p>';
    }

    public static init() {
        Array.from(document.querySelectorAll('.youtube')).forEach((el) => {
            if (el instanceof HTMLElement) {
                new Youtube(el);
            }
        });
    }
}

export { Youtube };
