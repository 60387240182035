require('es6-promise').polyfill();
require('isomorphic-fetch');

interface IDocument {
    link: string;
    type: string;
    title: string;
    content: string;
    group: string;
    previewImage: string;
}

interface IAutocompleteResponse {
    link: string;
    title: string;
}

type IAutocompleteCallback = (result: IAutocompleteResponse[]) => void;

class Autocomplete {
    public static readonly DEBOUNCE_TIMEOUT = 200;

    public debounceTimeout: number;
    private _callback: IAutocompleteCallback;
    private _inputDebounceTimeout: number | undefined;
    private _inputElement: HTMLInputElement;

    constructor(
        el: HTMLInputElement,
        callback: IAutocompleteCallback,
        debounceTimeout = Autocomplete.DEBOUNCE_TIMEOUT,
    ) {
        this._callback = callback;
        this._inputElement = el;
        this.debounceTimeout = debounceTimeout;

        this._inputElement.addEventListener('input', this._onInputChange.bind(this));
        this._inputElement.addEventListener('focusin', this._onInputChange.bind(this));
        (this._inputElement.parentElement as HTMLElement).addEventListener('focusout', this._onFocusOut.bind(this));
    }

    private _onInputChange(e: any) {
        let list = this._inputElement.nextElementSibling as HTMLElement;
        let parent = list.parentElement as HTMLElement;
        let style = window.getComputedStyle(list);
        if (parent.contains(e.relatedTarget) && (style.display === 'block' || style.display === 'flex')) return;
        window.clearTimeout(this._inputDebounceTimeout);
        this._inputDebounceTimeout = window.setTimeout(
            this._performSearch.bind(this),
            this.debounceTimeout,
            this._inputElement.value,
        );
    }

    private _onFocusOut(e: any) {
        let list = this._inputElement.nextElementSibling as HTMLElement;
        let parent = list.parentElement as HTMLElement;
        let style = window.getComputedStyle(list);
        if (parent.contains(e.relatedTarget) && (style.display === 'block' || style.display === 'flex')) return;
        window.clearTimeout(this._inputDebounceTimeout);
        this._inputDebounceTimeout = window.setTimeout(() => {
            this._callback([]);
        }, this.debounceTimeout);
    }

    private _performSearch(text: string) {
        this._inputDebounceTimeout = undefined;

        if (!text) {
            this._callback([]);
            return;
        }

        Autocomplete.search(text, this._inputElement)
            .then(this._callback)
            .catch((err) => {
                console.warn('Error while fetching autocomplete response', err);
            });
    }

    public static async search(text: string, input: HTMLElement): Promise<IAutocompleteResponse[]> {
        const request = await fetch(input.dataset.suggestUrl + '?tx_solr[queryString]=' + encodeURIComponent(text));
        const response = await request.json();

        return Object.keys(response.suggestions).map((suggestion: string) => {
            return {
                title: suggestion,
                link: input.dataset.urlTemplate + '?tx_solr[q]=' + encodeURIComponent(suggestion),
            };
        });
    }
}

export { Autocomplete, IAutocompleteResponse, IAutocompleteCallback };
