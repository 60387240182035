import { breakpointHelper } from '../Helper/Breakpoints';
import { Animate } from '../Helper/Animate';
import { Autocomplete, IAutocompleteResponse } from '../Helper/Autocomplete';
import { ArrowNavigation, HTMLListElement } from '../Helper/ArrowNavigation';

class HeaderSearch {
    private _element: HTMLElement;
    private _animate: Animate;
    private _input: HTMLInputElement | undefined;
    private _button: HTMLElement | undefined;
    private _status: HTMLElement | undefined;
    private _autocomplete: HTMLElement | undefined;
    private _arrowNavigation: ArrowNavigation | undefined;

    constructor(element: HTMLElement) {
        this._element = element;
        this._animate = new Animate('header-search--mobile', 'header-search--mobile-open');

        const input = element.querySelector('.header-search__input');
        const status = element.querySelector('.header-search__status');
        const button = element.querySelector('.header-search__submit');

        if (input instanceof HTMLInputElement && status instanceof HTMLElement && button instanceof HTMLElement) {
            this._input = input;
            this._status = status;
            this._button = button;
        }

        const autocomplete = element.querySelector('.header-search__autocomplete');
        if (autocomplete instanceof HTMLElement && input) {
            this._autocomplete = autocomplete;
            new Autocomplete(input as HTMLInputElement, this._autocompleteCallback.bind(this));
            this._arrowNavigation = new ArrowNavigation(
                autocomplete as HTMLListElement,
                'header-search__autocomplete-item--active',
                input as HTMLInputElement,
                button as HTMLElement,
            );
        }

        element.addEventListener('click', HeaderSearch._onElementClick);

        const submit = element.querySelector('.header-search__submit');
        if (submit) {
            submit.addEventListener('click', this._onSubmitClick.bind(this));
        }

        document.addEventListener('click', this._onOutsideClick.bind(this));
    }

    private _updateStatus(resultCount: number) {
        if (!this._status) {
            return;
        }

        if (!resultCount) {
            return;
        }

        if (resultCount === 0) {
            this._status.innerText = 'Keine Ergebnisse.';
        } else if (resultCount === 1) {
            this._status.innerText = resultCount + ' Ergebnis vorhanden.';
        } else if (resultCount === undefined) {
            this._status.innerText = '';
        } else {
            this._status.innerText = resultCount + ' Ergebnisse vorhanden.';
        }
    }

    private _onSubmitClick(event: Event): void {
        if (
            breakpointHelper.breakpoints.lgAndUp ||
            (this._element.classList.contains('header-search--mobile-open') && this._input && this._input.value)
        ) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        this._animate
            .toggle(this._element)
            .then(() => {
                let searchInput = this._input as HTMLInputElement;
                window.setTimeout(() => {
                    let inputDisplay = window.getComputedStyle(searchInput).display;
                    if (inputDisplay === 'block') {
                        (this._input as HTMLInputElement).focus();
                    } else {
                        (this._button as HTMLElement).focus();
                    }
                }, 50);
            })
            .catch(() => {
                console.debug('Navigation animation aborted');
            });
    }

    private _onOutsideClick(): void {
        if (this._element.classList.contains('header-search--mobile-open')) {
            this._animate.hide(this._element).catch(() => {
                console.debug('Navigation animation aborted');
            });
        }
    }

    private _autocompleteCallback(results: IAutocompleteResponse[]): void {
        if (!this._autocomplete) {
            return;
        }

        this._arrowNavigation?.reset();

        while (this._autocomplete.firstElementChild) {
            this._autocomplete.removeChild(this._autocomplete.firstElementChild);
        }

        if (results.length) {
            (this._input as HTMLElement).setAttribute('aria-expanded', 'true');
            this._autocomplete.classList.add('header-search__autocomplete--visible');

            results.forEach((result) => {
                const li = document.createElement('li');
                const a = document.createElement('a');

                li.setAttribute('tabindex', '-1');
                li.setAttribute('role', 'option');
                li.setAttribute('aria-selected', 'false');

                a.classList.add('header-search__autocomplete-link');
                a.href = result.link;
                a.textContent = result.title;

                li.appendChild(a);
                (this._autocomplete as HTMLElement).appendChild(li);
            });
            this._updateStatus(results.length);
        } else {
            (this._input as HTMLElement).setAttribute('aria-expanded', 'false');
            this._autocomplete.classList.remove('header-search__autocomplete--visible');
            (this._status as HTMLElement).innerText = '';
        }
    }

    private static _onElementClick(event: Event): void {
        event.stopPropagation();
    }

    public static init(): void {
        Array.from(document.querySelectorAll('.header-search')).forEach((search) => {
            if (search instanceof HTMLElement) {
                new HeaderSearch(search);
            }
        });
    }
}

export { HeaderSearch };
