class ScrollTable {
    public static init() {
        // Search the class contenttable and wrap it with the class scroll-table
        var elms = document.getElementsByClassName('contenttable');
        var div = document.createElement('div');
        div.className = 'scroll-table';

        // Loops backwards to prevent having to clone the wrapper on the
        // first element (see `child` below).
        for (var i = elms.length - 1; i >= 0; i--) {
            var child = i > 0 ? div.cloneNode(true) : div;
            var el = elms[i];

            // Cache the current parent and sibling.
            var parent = el.parentNode;
            var sibling = el.nextSibling;

            // Wrap the element (is automatically removed from its current
            // parent).
            child.appendChild(el);

            // If the element had a sibling, insert the wrapper before
            // the sibling to maintain the HTML structure; otherwise, just
            // append it to the parent.
            if (parent) {
                if (sibling) {
                    parent.insertBefore(child, sibling);
                } else {
                    parent.appendChild(child);
                }
            }
        }
    }
}

export { ScrollTable };
