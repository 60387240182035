import { FocusVisible } from './Lib/FocusVisible';
import { FilterMobileItem } from './Molecules/FilterMobileItem';
import { MainHeader } from './Organisms/MainHeader';
import { NavMain } from './Molecules/NavMain';
import { HeaderSearch } from './Molecules/HeaderSearch';
import { ContactPartner } from './Molecules/ContactPartner';
import { ExternalLink } from './Molecules/ExternalLink';
import { Youtube } from './Molecules/Youtube';
import { MainSearch } from './Molecules/MainSearch';
import { NewsSlider } from './Organisms/NewsSlider';
import { ScrollTable } from './Organisms/ScrollTable';
import GLightbox from 'glightbox';
import { SolrLinks } from './Helper/SolrLinks';

// import $ from 'jquery';
import 'bootstrap/js/dist/collapse';

(function init() {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', bootstrap);
    } else {
        bootstrap();
    }
})();

function bootstrap() {
    // INIT LIBS
    //*******************************************
    FocusVisible();

    // INIT ORGANISMS
    //*******************************************
    MainHeader.init();
    NewsSlider.init();
    ScrollTable.init();

    // INIT MOLECULES
    //*******************************************
    NavMain.init();
    HeaderSearch.init();
    ContactPartner.init();
    ExternalLink.init();
    Youtube.init();
    FilterMobileItem.init();
    MainSearch.init();

    SolrLinks.init();

    // add event listener for services
    document.addEventListener('serviceEmbeded', (evt) => {
        // yumpu service (browsable pdf)
        if (evt.detail.service === 'yumpu') {
            const _yumpuId = evt.target.dataset.yumpuid;
            evt.detail.newEl.src = evt.detail.newEl.dataset.src + '/' + _yumpuId;
        }
    });

    const lightbox = GLightbox({
        touchNavigation: true,
    });

    document.querySelectorAll('[data-toggle=printPage]').forEach((el) => {
        el.addEventListener('click', (evt) => {
            window.print();
            evt.preventDefault();
        });
    });
}
