import { Animate } from '../Helper/Animate';

class MainHeader {
    private _navButton: HTMLElement | undefined;
    private _closeButton: HTMLElement | undefined;
    private _collapse: HTMLElement | undefined;
    private _body: HTMLBodyElement | null;
    private _animate: Animate;
    private readonly focusTrapEventHandler: (event: KeyboardEvent) => void;
    private focusTrapElements?: HTMLElement[];
    private preFocusTrapActiveElement?: HTMLElement;

    constructor(header: HTMLElement) {
        this.focusTrapEventHandler = this.focusTrapNavigator.bind(this);
        const collapse = header.querySelector('.main-header__collapse') as HTMLElement;
        const navButton = document.querySelector('.main-header__toggle-nav') as HTMLElement;
        const closeButton = document.querySelector('.main-header__toggle-close') as HTMLElement;
        const bodyEl = document.querySelector('body');
        this._body = bodyEl;
        if (collapse && navButton && closeButton) {
            this._collapse = collapse;
            this._navButton = navButton;
            this._closeButton = closeButton;

            collapse.addEventListener('click', this._onOutsideClick.bind(this));

            Array.from(header.querySelectorAll('.main-header__toggle-mobile')).forEach((toggle) => {
                toggle.addEventListener('click', this._toggleCollapse.bind(this));
            });
        }

        this._animate = new Animate(
            'main-header__collapse--visible',
            'main-header__collapse--open',
            'main-header__collapse--open-active',
        );
    }

    private _toggleCollapse(event: Event): void {
        if (event) {
            event.preventDefault();
        }

        if (this._collapse) {
            this._animate
                .toggle(this._collapse)
                .then(() => {
                    if (this._navButton?.getAttribute('aria-expanded') === 'false') {
                        this._navButton?.setAttribute('aria-expanded', 'true');
                        this._collapse?.setAttribute('aria-hidden', 'false');
                        this._body?.classList.add('is-open__nav');
                        requestAnimationFrame(() => {
                            this._enableFocusTrap();
                            this._closeButton?.focus();
                        });
                    } else {
                        this._navButton?.setAttribute('aria-expanded', 'false');
                        this._collapse?.setAttribute('aria-hidden', 'true');
                        this._body?.classList.remove('is-open__nav');
                        requestAnimationFrame(() => {
                            this._disableFocusTrap();
                            this._navButton?.focus();
                        });
                    }
                })
                .catch(() => {
                    console.debug('Navigation animation aborted');
                });
        }

        window.addEventListener('resize', () => {
            let mq = window.matchMedia('(min-width: 992px)');
            if (mq) {
                this._body?.classList.remove('is-open__nav');
            }
        });
    }

    private _enableFocusTrap(): void {
        this.preFocusTrapActiveElement = undefined;
        if (document.activeElement instanceof HTMLElement) {
            this.preFocusTrapActiveElement = document.activeElement;
            document.activeElement.blur();
        }
        document.addEventListener('keydown', this.focusTrapEventHandler);
    }

    private _disableFocusTrap(): void {
        document.removeEventListener('keydown', this.focusTrapEventHandler);
        if (this.preFocusTrapActiveElement instanceof HTMLElement) {
            this.preFocusTrapActiveElement.focus();
        }
    }

    private focusTrapNavigator(event: KeyboardEvent) {
        if (event.key !== 'Tab') {
            return;
        }
        event.preventDefault();
        this.focusTrapElements = this.getFocusTrapElements();
        const currentlyFocusedElementIndex = this.focusTrapElements.indexOf(document.activeElement as HTMLElement);
        let nextFocusElementIndex = currentlyFocusedElementIndex + (event.shiftKey ? -1 : 1);
        if (nextFocusElementIndex < 0) {
            nextFocusElementIndex = this.focusTrapElements.length - 1;
        } else if (nextFocusElementIndex >= this.focusTrapElements.length) {
            nextFocusElementIndex = 0;
        }
        let nextElementIndex = this.focusTrapElements[nextFocusElementIndex];
        requestAnimationFrame(() => {
            nextElementIndex.focus();
        });
    }

    private getFocusTrapElements() {
        const tabbables = this._collapse?.querySelectorAll('a, button') as any as Array<HTMLElement>;
        return Array.from(tabbables).filter((element) => {
            return element.offsetWidth > 0 || element.offsetHeight > 0 || element.getClientRects().length > 0;
        });
    }

    private _onOutsideClick(event: Event): void {
        if (this._collapse && event.target === event.currentTarget) {
            this._animate
                .hide(this._collapse)
                .then(() => {
                    this._body?.classList.remove('is-open__nav');
                    this._navButton?.setAttribute('aria-expanded', 'false');
                    this._collapse?.setAttribute('aria-hidden', 'true');
                    requestAnimationFrame(() => {
                        this._disableFocusTrap();
                        this._navButton?.focus();
                    });
                })
                .catch(() => {
                    console.debug('Navigation animation aborted');
                });
        }
    }

    public static init(): void {
        Array.from(document.querySelectorAll('.main-header')).forEach((header) => {
            if (header instanceof HTMLElement) {
                new MainHeader(header);
            }
        });
    }
}

export { MainHeader };
